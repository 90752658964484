.bg-1 {
    padding: 15px;
    background: var(--bg-blinking-color-2);
}


.padding-lr p {
    padding: 0px 175px;
}

.about_us {
    padding: 80px 0px;
    background: var(--bg-blinking-color-2);
}

.about_us h1 {
    line-height: 52px;
}

.about_us h2 {
    margin: 30px 0px;

}
.about_us h3 {
    margin: 30px 0px 20px 0px;
    color:#e5322c;

}


.about_us img {
    margin: 30px 0px;

}

.about_us .btn_one {
    color: var(--text-color-1);
    margin: 10px 0px;
}

.widget {
    margin: 25px auto;
}

.widget .fa {
    font-size: 48px;
    color: #e5322c;
}

.widget a.font-12 {
    font-size: 12px;
    color: #1f406d;
    margin-left: 15px;
}

.widget .font-16 {
    font-size: 16px;
    margin: 0px 0px 0px 15px;
}

.widget .font-13 p {
    font-size: 13px;
    margin: 0px 0px 0px 15px;
    line-height: 17px;
    color: #000;
}


/* Responsive.css */

@media (max-width: 374.98px) {}

@media (min-width: 375.98px) and (max-width: 575.98px) {}


@media (min-width: 576px) and (max-width: 767.98px) {}


@media (min-width: 768px) and (max-width: 991.98px) {}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) and (max-width: 1399.98px) {}