.subscribe .subscribe-form input[type="email"] {
    position: relative;
    display: block;
    background: #fff;
    border: 1px solid #f1f1f1;
    color: var(--text-color-2);
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    height: 52px;
    margin-bottom: 0px;
    padding: 0 20px;
    transition: all 500ms ease;
}


.subscribe .subscribe-form button {
    position: relative;
	z-index: 1;
	font-size: 18px;
	text-align: center;
	padding: 14px 24px;
	text-transform: capitalize;
	transition: all ease-in-out .5s;
	background: #0b6ea9;
	color: #fff;
	display: inline-block;
	border-radius: 10px;
	border: none;
	box-shadow: 0 0 0 4px rgba(255, 255, 255, .1);
	overflow: hidden;
    width:100%;
    margin-top:25px;
}

.subscribe-form button ::before {
	position: absolute;
	left: 0;
	width: 0;
	height: 100%;
	top: 0;
	content: '';
	background: #fdc703;
	color: #fff !important;
	transition: all ease-in-out .5s;
	z-index: -1;
}

.subscribe-form button :hover::before{
    width: 100%;
    transition: all ease-in-out .5s
}

.subscribe-form button:hover{
    color: #fff !important;
    transition: all ease-in-out .5s
}

.subscribe .subscribe-box .text p {
    color: var(--text-color-2);
    font-size: 15px;
    margin-top: 15px;
}

.subscribe .our-info-box .text p {
    color: var(--text-color-2);
    margin: 0;
}

.subscribe .our-info-box .text {
    position: relative;
    display: block;
    padding-bottom: 23px;
}

.subscribe-box span {
    /* margin-right: 35px;*/

}

.subscribe .subscribe-box .text p span {
    color: var(--text-color-5);
}