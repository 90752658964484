.btn-info {
    margin-left: 10px;
    color: var(--text-color-1);
}

.btn-info:hover {

    color: var(--text-color-1);
}

.btn_one {
    font-size: 14px;
    text-align: center;
    padding: 13px 24px 11px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    background-color: var(--bg-blinking-color-3);
    color: var(--text-color-1);
    border-radius: 10px;
    border: none;
}

.btn_one:hover {
    color: var(--text-color-1) !important;
    border: 2px solid #ef2853;
    background: transparent !important;
    transition: all 0.3s ease-in-out;
}


.btn_two {
    position: relative;
    z-index: 1;
    font-size: 14px;
    text-align: center;
    padding: 13px 24px 11px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    background-color: var(--bg-blinking-color-3);
    color: var(--text-color-1);
    border-radius: 10px;
    border: none;
}

.btn_two:hover {
    color: var(--text-color-1) !important;
    border: 2px solid #ef2853;
    background: transparent !important;
    transition: all 0.3s ease-in-out;
}

.btn_three {
    position: relative;
    z-index: 1;
    font-size: 14px;
    text-align: center;
    padding: 13px 24px 11px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    background-color: var(--bg-blinking-color-3);
    color: var(--text-color-1);
    border-radius: 10px;
    border: none;

}

.btn_three:hover {
    color: var(--text-color-4) !important;
    border: 2px solid #ef2853;
    background: transparent !important;
    transition: all 0.3s ease-in-out;
}

.btn_three .fa {
    margin-left: 5px;
}

.btn_four {
    position: relative;
    display: inline-block;
    background: #e9212e;
    color: var(--text-color-1);
    font-size: 15px;
    line-height: 60px;
    font-weight: 500;
    text-transform: capitalize;
    font-family: 'Rubik', sans-serif;
    padding: 0 30px 0;
    transition: all 500ms ease;
    border: 0px;
}

.btn_four span::before {
    font-size: 26px;
    line-height: 14px;
    display: inline-block;
    padding-left: 10px;
    position: relative;
    top: 4px;
}

.nir-btn {
	position: relative;
	z-index: 1;	
	text-align: center;
	padding: 14px 24px;	
	transition: all ease-in-out .5s;
	background: #051e82;
	color: #fff;
	display: inline-block;
	border-radius: 10px;
	border: none;
	box-shadow: 0 0 0 4px rgba(255,255,255,.1);
	overflow: hidden;
    font-size: 15px;
	text-transform: uppercase;
	color: #777;
    margin:25px  auto 0px auto;
}
.white {
	color: #fff !important;
}
.nir-btn::before {
	position: absolute;
	left: 0;
	width: 0;
	height: 100%;
	top: 0;
	content: '';
	background: #ed1b24;
	color: #fff !important;
	transition: all ease-in-out .5s;
	z-index: -1;
}

.nir-btn:hover::before{
    width: 100%;
    transition: all ease-in-out .5s
}

.nir-btn:hover{
    color: #fff !important;
    transition: all ease-in-out .5s
}
.border-t {
    border-top: 2px dashed #cccccc5e;
}